<template>
  <div>
    <div class="withdrawContainer" v-if="$route.path === '/RedBag/Withdraw'">
    <div v-wechat-title="$route.meta.title"></div>
        <div class="withdrawTop">
            <div class="withdrawTopLeft">如何获取更多红包收益</div>
            <div class="withdrawTopRight" @click="more">
                <span>点击查看</span>&nbsp;&nbsp;
                <img src="../img/更多 2@2x.png">
            </div>
            <van-popup v-model="show1">
                <div class="alertContainer">
                    <div class="alertTitle">如何获取红包收益？</div>
                    <div class="alertText">
                        实时关注云美摄APP活动及活动通知，并参加得红包的活动，按活动要求完成活动任务即有机会获取红包收益。
                    </div>
                    <div class="know" @click="close(2)">我知道了</div>
                </div>
            </van-popup>
        </div>
        <div class="withdrawHeader">
            <div class="totleCount">{{totleCount}}元</div>
            <div class="withdrawed">累计提取{{withdrawed}}元</div>
            <div class="withdrawDesc">30天内红包收益没有变动自动清零</div>
        </div>
        <div class="greey"></div>
        <div class="moneySelecter">
            <div class="moneyItem " 
            v-for="(item, index) in moneyItem" :key="index"
            @click="selected(item)"
            :class="{moneyItem:true,selected:item == checkedMoney}"
            >{{item}}元</div>
        </div>
        <div class="footer">
            <div class="wxLogin">
                <div class="wxLoginLeft">
                    <img :src="`${user_avant}`" class="user_avant" v-if="isUserAvant">
                    <img src="../img/微信@2x.png" class="user_avant" v-else>
                    <div class="boundInfo">
                        <div class="userInfo" v-if="isUserName">微信账户-{{userInfo}}</div>
                        <div class="userInfo" v-else>未绑定</div>
                        <div class="boundDesc">每人只能用一个账号参与</div>
                    </div>
                </div>
                <div class="wxLoginRight" @click="bangding" v-show="isBangding">立即绑定</div>
            </div>
            <div class="rule">
                <span>提取前请阅读</span>
                <span @click="$router.push(`/RedBag/Rule`)">《提取规则说明》</span>
            </div>
            <div class="goMoney" @click="goMoney">立即提取</div>
        </div>
        <van-popup v-model="show">
            <div class="alertContainer">
                <div class="alertText">
                    可提取金额不足，快去做活动赢取红包吧。
                </div>
                <div class="know" @click="close(1)">我知道了</div>
            </div>
        </van-popup>
    </div>
    <div v-else>
        <router-view></router-view>
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant';
export default{
    data(){
        return{
            totleCount:0,
            withdrawed:0,
            moneyItem:["5","10","20","60","100","200"],
            checkedMoney:'5',
            userInfo:'未绑定',
            show:false,
            show1:false,
            userId:'',
            token:'',
            user_avant:'',
            openId:'',
            isUserAvant:false,
            isUserName:false,
            isBangding:true,
            app_key:'aki$G9$w0BA*QE',
            secret_key:"MICdgIBgkqhkiG9w0QEFoGBALXAwl29R7n2d",
            date: String(new Date().valueOf()).slice(0, 10),
            activity_id:0,
        }
    },
    async mounted(){
        //获取userId、token、活动ID
        this.userId = this.getRequest().userId ? this.getRequest().userId : "";
        this.token = this.getRequest().token ? this.getRequest().token : "";
        this.activity_id = this.getRequest().activity_id ? this.getRequest().activity_id : "";
        let sign = this.$md5(this.getSign(this.app_key,this.date,"RedPacket/getList",this.secret_key));
        let {data,errNo,message} = await this.$api.getRedBagInfo(this.app_key,this.date,sign,this.token,this.userId)
        if(errNo == 0){
            this.withdrawed = data.balance.withdraw_amount
            this.totleCount = data.balance.amount
        }

        //
        
        window.GET_WXUSERINFO_MEISHE = this.saveWxInfo;

        await this.queryAuthorization()
    },
    methods:{
        selected(item){
            this.checkedMoney = item
        },
        async goMoney(){
            if(this.openId != ''){
                if(Number(this.checkedMoney) <= Number(this.totleCount)){
                    let sign = this.$md5(this.getSign(this.app_key,this.date,"RedPacket/withdrawalApply",this.secret_key));
                    let{data,errNo,message} = await this.$api.goMoney(this.app_key,this.date,sign,this.token,this.userId,this.openId,this.checkedMoney,this.activity_id)
                    if(errNo == 0){
                        this.$router.replace(`/RedBag/Withdraw/Result?userId=${this.userId}&token=${this.token}&type=${1}`)
                    }
                    Toast(message)
                }else{
                    this.show = true
                }
            }else{
                Toast('请先绑定微信账号')
            }
        },
        close(num){
            if(num == 1){
                this.show = false
            }else{
                this.show1 = false
            }
        },
        more(){
            this.show1 = true
        },
        //格式化URL
        getRequest: () => {
            var url = window.location.search; //获取url中"?"符后的字串
            var theRequest = new Object();
            if (url.indexOf("?") != -1) {
                var str = url.substr(1);
                var strs = str.split("&");
                for (var i = 0; i < strs.length; i++) {
                    theRequest[strs[i].split("=")[0]] = decodeURI(
                        strs[i].split("=")[1]
                    );
                }
            }

            return theRequest;
        },
        //查询授权
        async queryAuthorization(){
            let sign = this.$md5(this.getSign(this.app_key,this.date,"RedPacket/queryAuthorization",this.secret_key));
            let {data,errNo,message} = await this.$api.cheakAuthorization(this.app_key,this.date,sign,this.token,this.userId)
            if(errNo == 0){
                if(Object.keys(data).length == 0){
                    this.isBangding = true
                    return
                }
                if(data.headimgurl){
                    this.user_avant = data.headimgurl
                    this.isUserAvant = true
                }else{
                    this.isUserAvant = false
                }
                if(data.nickname){
                    this.userInfo = data.nickname
                    this.isUserName = true
                }else{
                    this.isUserName = false
                }
                this.openId = data.openid
                this.isBangding = false
            }
        },
        async saveWxInfo(openId, avater, nickname,isSuccess){
            if (isSuccess) {
                let sign = this.$md5(this.getSign(this.app_key,this.date,"RedPacket/saveAuthorization",this.secret_key));
                let {data,errNo,message} = await this.$api.saveAuthorization(this.app_key,this.date,sign,this.token,this.userId,openId,nickname,avater)
                if(errNo == 0){
                    if(avater){
                    this.user_avant = avater
                    this.isUserAvant = true
                    }else{
                        this.isUserAvant = false
                    }
                    if(nickname){
                        this.userInfo = nickname
                        this.isUserName = true
                    }else{
                        this.isUserName = false
                    }
                    this.openId = openId
                    this.isBangding = false
                }else{
                     Toast(message)
                }
            } else {
                Toast('授权失败')
            }
        },
        bangding(){
            window.location.href = 'http://meisheapp.com?command=queryWechatUserInfo'
        },
        //获取签名
        getSign: (app_key, date, URL, secret_key) => {
            var sign ="app_key=" +app_key +"&" +"timestamp=" +date +"&" +"uri=" + URL +"&" +"secret_key=" +secret_key;
            return sign;
        },
        


    }
}
</script>
<style scoped>
.greey{
    background-color: #EDEDED;
    width: 100vw;
    height: 8px;
}
.withdrawTop{
    width: 375px;
    height: 35px;
    background: #FFF6CF;
    display: flex;
    align-items: center;
    padding: 0 29px;
    box-sizing: border-box;
}
.withdrawTopLeft{
    font-size: 12px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #D99113;
}
.withdrawTopRight{
    margin-left: 128px;
}   
.withdrawTopRight span{
    font-size: 12px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #D99113;
}
.withdrawTopRight img{
    width: 10px;
    height: 8px;
}
.totleCount{
    width: 180px;
    height: 50px;
    font-size: 36px;
    font-family: DINOT-Bold, DINOT;
    font-weight: bold;
    color: #323232;
    margin: 21px auto 0;
    text-align: center;
}
.withdrawed{
    width: 135px;
    height: 22px;
    font-size: 16px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #919191;
    margin: 0 auto;
    text-align: center;
}
.withdrawDesc{
    width: 250x;
    height: 20px;
    font-size: 14px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #919191;
    margin:20px auto;
    text-align: center;
}
.moneySelecter{
    padding: 12px 11px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.moneyItem{
    width: 114px;
    height: 71px;
    line-height: 71px;
    text-align: center;
    background: #FFFFFF;
    border-radius: 5px;
    border: 1px solid #EDEDED;
    font-size: 22px;
    font-family: DINOT-Bold, DINOT;
    font-weight: bold;
    color: #323232;
    box-sizing: border-box;
    margin-bottom:8px; 
}
.selected{
    border: 2px solid #F4574B;
    background: url('../img/Group@2x.png') no-repeat;
    background-size: 28px 25px;
    background-position: -2px -2px;
}
.wxLogin{
    display: flex;
    padding: 0 23px;
    box-sizing: border-box;
    margin-top: 115px;
    align-items: center;
}
.user_avant{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.boundInfo{
    width: 161px;
    display: flex;
    flex-direction: column;
    margin-left: 12px;
}
.wxLoginLeft{
    display: flex;
    align-items: center;
}
.userInfo{
    font-size: 16px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space:nowrap;
}
.boundDesc{
    width: 135px;
    height: 17px;
    font-size: 12px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #999999;
}
.footer{
    position: fixed;
    bottom: 20px;
}
.wxLoginRight{
    margin-left: 37px;
    width: 68px;
    height: 24px;
    border-radius: 1px;
    border: 1px solid #E86258;
    font-size: 12px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #E86258;
    line-height: 24px;
    text-align: center;
}
.rule{
    width: 200px;
    height: 17px;
    margin: 18px auto 11px;
    text-align: center;
}
.rule span:nth-of-type(1){
    color: #9E9E9E;
}
.rule span:nth-of-type(2){
    color: #E86258;
}
.goMoney{
    width: 345px;
    height: 46px;
    background: #E86258;
    border-radius: 4px;
    font-size: 17px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 46px;
    text-align: center;
    margin: 0 15px;
    ;
}
.alertContainer{
    padding: 26px 21px 0px;
}
.alertTitle{
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    margin-top: 10px;
}
.alertText{
    width: 293px;
    font-size: 14px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #666666;
    line-height: 26px;
    margin-top: 6px;
}
.know{
    width: 192px;
    height: 46px;
    background: #E86258;
    border-radius: 4px;
    font-size: 17px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 46px;
    text-align: center;
    margin: 24px auto;
}
</style>